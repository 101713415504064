import banner_1 from "@/assets/home/banner_1.jpg";
import banner_2 from "@/assets/home/banner_2.jpg";
import banner_3 from "@/assets/home/banner_3.jpg";
import banner_4 from "@/assets/home/banner_4.jpg";
import banner_5 from "@/assets/home/banner_5.jpg";
import logo from "@/assets/home/logo.png";
import bk from "@/assets/home/bk.jpg"
import mail from "@/assets/home/mail.png"
import phone from "@/assets/home/phone.png"
import site from "@/assets/home/site.png"
import code from "@/assets/home/code.jpg"
import dev1 from "@/assets/home/dev1.jpg"
import dev2 from "@/assets/home/dev2.jpg"
import dev3 from "@/assets/home/dev3.jpg"
import dev4 from "@/assets/home/dev4.jpg"
export default {
    data() {
        return {
            logo,
            bk,
            mail,
            phone,
            site,
            code,
            bannerData: [
                {
                    cover: banner_1,
                    url: 'http://www.sanxiangchina.com/#',
                },
                {
                    cover: banner_2,
                    url: 'http://www.sanxiangchina.com/#',
                },
                {
                    cover: banner_3,
                    url: 'http://www.sanxiangchina.com/#',
                },
                {
                    cover: banner_4,
                    url: 'http://www.sanxiangchina.com/#',
                },
                {
                    cover: banner_5,
                    url: 'http://www.sanxiangchina.com/#',
                },
            ],
            devList: [
                {
                    cover: dev1,
                    name: '世赛项目-电气装置'
                },
                {
                    cover: dev2,
                    name: '工业机器人应用技能'
                },
                {
                    cover: dev3,
                    name: '工业机器人综合技能'
                },
                {
                    cover: dev4,
                    name: '世赛项目-工业控制'
                },
            ]
        }
    },
    methods: {
        redirect() {
            this.$confirm('是否跳转到主站页面？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                window.open("http://www.sanxiangchina.com/#");
            }).catch(() => {
                
            });
        }
    },
    mounted() {
        document.querySelector('link[rel="icon"]').setAttribute('href', 'favicon.ico')
    }
}