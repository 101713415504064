<template>
  <div id="app">
    <!-- <router-view/> -->
    <home></home>
  </div>
</template>

<script>
import home from "@/views/Home/Home.vue"
export default {
  components: {
    home
  }
}
</script>

<style lang="scss">
#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
}
</style>
